@charset "UTF-8";

$pageStatus: "general";

@import "../common/mixins"; //믹스인모음
@import "../common/keyframes"; //믹스인모음

@import "../common/helpers/variables"; //변수모음
@import "../common/helpers/copyright"; //copyright
@import "../common/helpers/reset"; //스타일 초기화

// default.css와 같이 사용
@import "../common/keyframes";
@import "../common/animation";
@import "../common/partial/ie_end_support"; //IE 지원 종료
@import "../common/partial/skipnavi"; //스킵네비
@import "../common/partial/common"; //공통기능클래스
@import "../common/partial/button"; //공통버튼
@import "../common/partial/adlay"; //광고+도움말레이어
@import "../common/partial/header"; //공통헤더
@import "../common/partial/footer"; //공통푸터
@import "../common/partial/banner"; //공통배너, 윙배너
@import "../common/partial/layer_collection"; //공통레이어
@import "../common/partial/listing"; //공통 상품정보, 리스팅
@import "../eui/component/c-starrate";
@import "../eui/component/c-card-item";
@import "../common/partial/myhistory"; //공통 마이히스토리
@import "../common/partial/c-netfunnel"; //공통 넷퍼넬 팝업
@import "../common/partial/lazyload";
@import "../common/partial/video11box";
// -- default.css와 같이 사용

// Layout
#wrapBody {
    min-width: 1280px;
}
.l_body {
    #layBodyWrap {
        //layBodyWrap 다른 개편 안된 페이지와 동일한 스타일
        position: relative;
        z-index: 20;
        width: 100%;
        outline: none;
    }
}
.l_content {
    position: relative;
    min-height: 300px;
}
.l_width {
    width: 1240px;
    margin: 0 auto;
}
.l_width_wide {
    width: 100%;
}

// My History ver.2
.my_history2_wrap {
    width: 1240px;
    margin: 0 auto 10px auto;
    background: #fff;
    font-size: 12px;
    font-family: $font-family-default;
    line-height: 18px;
    letter-spacing: 0;

    &:after {
        clear: both;
        content: "";
        display: block;
    }

    .hgroup {
        overflow: hidden;
        height: 34px;
        border: 1px solid #515da5;
        background: #5f6cb2;

        h3 {
            float: left;
            padding: 11px 9px 0 19px;
        }

        p {
            float: left;
            color: #dfe4ff;
            font-size: 11px;
            line-height: 34px;
        }
    }

    input.in_clear {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 13px;
        height: 13px;
        border: 0;
        background: url("/img/common/myhistorybtn_delete.gif") no-repeat 0 0;
        font-size: 0;
        text-indent: -9999px;
        cursor: pointer;
    }

    .noview_product {
        font-size: 12px;
        padding-top: 115px;
        text-align: center;
    }

    .my_wrap {
        position: relative;
        height: 245px;
        zoom: 1;
        border: 1px solid #ddd;
        border-top: 0;
    }

    .list_wrap {
        .tab {
            display: block;
            width: 126px;
            height: 81px;
            border-right: 1px solid #ddd;
            background: #f5f5f5;
            font-size: 12px;
            letter-spacing: 0;

            span {
                display: block;
                text-align: center;
                font-weight: normal;
                line-height: 81px;
                color: #999;
            }

            a {
                display: block;
                color: #333;
            }
        }

        + .list_wrap .tab {
            border-top: 1px solid #ddd;
        }

        &.selected {
            .tab {
                background: #fff;
                border-right: 0;

                span {
                    color: #4d6ccd;
                    font-weight: bold;
                }

                em {
                    font-weight: normal;
                }
            }

            .innerbox {
                display: block;
            }
        }
    }

    .btnctr_pn {
        position: absolute;
        top: 20px;
        left: 0;
        text-align: right;
        padding-right: 30px;

        button {
            position: absolute;
            z-index: 2;
            top: 88px;
            width: 30px;
            height: 30px;
            background: url("/img/default/main/prev_next.png") no-repeat 0 -149px;
            opacity: 0.2;
            filter: alpha(opacity = 20);
            -webkit-transition: opacity 0.3s;
            -moz-transition: opacity 0.3s;
            -o-transition: opacity 0.3s;
            transition: opacity 0.3s;
        }

        .in_prev {
            left: 0;
        }

        button.in_next {
            right: 0;
            background-position: -33px -149px;
        }
    }

    .prd_wrap:hover .btnctr_pn button,
    .recom_prd:hover .btnctr_pn button {
        background-position: 0 -114px;
        opacity: 0.2;
        filter: alpha(opacity = 20);
    }

    .prd_wrap:hover .btnctr_pn button.in_next,
    .recom_prd:hover .btnctr_pn button.in_next {
        background-position: -33px -114px;
    }

    .innerbox .btnctr_pn button {
        &:hover,
        &:focus {
            opacity: 0.7;
            filter: alpha(opacity = 70);
        }
    }

    .my_wrap {
        .innerbox {
            display: none;
            position: absolute;
            top: 0px;
            left: 127px;
            right: 0;
            zoom: 1;
        }

        .prd_wrap {
            position: relative;
            float: left;
            margin-top: 20px;
            padding-bottom: 0;
            width: 24%;
            min-width: 210px;
            height: 210px;
            border-right: 1px solid #ddd;

            .viewport {
                margin: 18px 20px 0;
            }

            .btnctr_pn {
                top: 0px;
            }

            .thumb {
                display: block;
                width: 150px;
                height: 150px;
            }

            .viewport img {
                max-width: 150px;
                max-height: 150px;
            }
        }
    }

    .product_wrap {
        position: relative;
        width: 150px;
        margin: 0 auto;
        text-align: center;

        a {
            display: block;
        }

        span.tit {
            display: block;
            overflow: hidden;
            height: 16px;
            padding-top: 8px;
            line-height: 16px;
            color: #4d4d4d;
            cursor: pointer;
        }
    }

    .price {
        display: block;
        color: #111;
        font-weight: bold;
        font-family: Tahoma, sans-serif;
        font-size: 13px;
        line-height: 17px;
    }

    .recom_prd {
        overflow: hidden;
        position: relative;
        height: 245px;
        margin: 0;

        h5 {
            padding: 20px 0 0 30px;
            color: #111;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
        }

        ul {
            overflow: hidden;
            height: 207px;
            margin: 0 20px;
        }

        li {
            float: left;
            width: 25%;
            height: 207px;
            text-align: center;

            a {
                display: block;
                width: 130px;
                margin: 0 auto;
                line-height: 16px;

                .thumb {
                    display: block;
                    width: 130px;
                    height: 130px;
                }

                img {
                    max-width: 130px;
                    max-height: 130px;
                }

                .tit {
                    display: block;
                    overflow: hidden;
                    padding-top: 12px;
                    height: 32px;
                }
            }
        }

        .btnctr_pn button.in_prev {
            left: -1px;
        }

        .noview_product {
            padding-top: 85px;
        }
    }
}

.latelyview2_conts {
    overflow: hidden;
    zoom: 1;
    border: 1px solid #d7dbe4;
    border-top: none;
    border-bottom: 1px solid #b1b1b1;
    padding: 5px 0;
    line-height: 18px;

    div {
        overflow: hidden;
        zoom: 1;

        &:first-child {
            margin-left: 0;
            border-right: none;
        }
    }

    h4 {
        float: left;
        padding-left: 20px;
        font-size: 12px;
        line-height: 18px;
        color: #333;
        letter-spacing: 0;
    }

    ul {
        float: left;
        max-width: 370px;
    }

    li {
        float: left;
        padding-left: 10px;
        white-space: nowrap;

        input {
            display: inline-block;
            overflow: hidden;
            width: 18px;
            height: 18px;
            border: none;
            background: url("/img/default/btn/delete06.gif") no-repeat 45% 45%;
            font-size: 0;
            text-indent: -9999px;
            vertical-align: top;
            cursor: pointer;
        }

        a {
            display: inline-block;
            color: #4d4d4d;
            vertical-align: top;
        }
    }
}

.loading_conts {
    padding: 80px 0 77px;
    text-align: center;
}

.btnctr_pn {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 42px;
    color: #666;
    font-size: 11px;
    font-family: Tahoma, "돋움", dotum, sans-serif;
    letter-spacing: 1px;

    em {
        color: #434343;
        font-weight: bold;
    }

    button {
        display: block;
        position: absolute;
        width: 19px;
        height: 18px;
        top: -1px;
        right: 18px;
        background: url("/img/default/btn/prev_next05.gif") no-repeat 0 0;
        font-size: 0;
        text-indent: -9999px;

        &.in_next {
            right: 0;
            background-position: 100% 0;
        }
    }
}

// new footer search
div.footer_search2 {
    position: relative;
    z-index: 1;
    width: 1240px;
    margin: 0 auto;
    padding: 20px 0 21px;
    border-top: 1px solid #d8d8da;
    background: #f1f1f1;
    font-size: 12px;
    font-family: $font-family-default;
    text-align: center;

    fieldset {
        display: inline-block;
        position: relative;
        height: 34px;
        overflow: hidden;
    }

    fieldset legend {
        position: absolute;
        font-size: 0;
        overflow: hidden;
        text-indent: -9999px;
    }

    input {
        vertical-align: middle;
    }

    fieldset.total_search {
        position: relative;
        zoom: 1;
        overflow: visible;
        width: 385px;
        margin-left: 0px;
        padding: 0 0 0 75px;
        background: url("/img/default/footer_search.png") no-repeat 0 0;

        h4 {
            display: block;
            float: left;
            height: 14px;
            width: 64px;
            margin: 9px 0 0 0px;
            *margin: 8px 0 0 0px;
            padding: 1px 8px 0 6px;
            color: #e81818;
            line-height: 14px;
            font-weight: normal;
            overflow: hidden;
            text-align: left;
        }

        .text {
            float: left;
            position: relative;
            width: 220px;
            top: 5px;
            left: 7px;
            padding: 2px 0;
            border: 1px solid #fff;
            color: #111;
            font-size: 12px;
            font-weight: bold;
        }

        .search {
            display: block;
            position: absolute;
            top: 0;
            right: 0px;
            width: 55px;
            height: 34px;
            padding: 0;
            border: 0;
            background: url("/img/default/footer_search.png") no-repeat -405px 0;
            font-size: 0;
            text-indent: -9999px;
            cursor: pointer;
        }
    }

    .btn_research {
        position: absolute;
        top: 37px;
        right: 20px;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        .ico_rsch {
            display: inline-block;
            width: 14px;
            height: 13px;
            margin: 0px 5px 2px 0;
            background: url("/img/browsing/search_img.gif") no-repeat -502px -390px;
            overflow: hidden;
            vertical-align: middle;

            em {
                visibility: hidden;
            }
        }
    }
}

// [FOOTER BANNER]
// MPSR-67376 런칭 후 삭제예정 시작
.ftr_banner {
    overflow: hidden;
    width: 1240px;
    margin: 0 auto 10px;
    text-align: center;

    a {
        display: block;
        position: relative;

        > img {
            max-width: 100%;
            vertical-align: top;
        }
    }

    .bnrborder {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 1px solid #000;
        opacity: 0.08;
        filter: alpha(opacity = 8);
        z-index: 2;
    }

    span.ad {
        display: block;
        position: absolute;
        top: 4px;
        right: 4px;

        img {
            margin: 0;
        }
    }
}
// MPSR-67376 런칭 후 삭제예정 끝

// dayclose popup
.dayclose_popwrap {
    position: relative;
    z-index: 110;
    width: 1200px;
    margin: 0 auto;
}

.daypop {
    position: absolute;
    z-index: 40;
    top: 0;
    left: 0;
    width: 430px;

    .daypop_cnt {
        zoom: 1;
        margin: 0;
        padding: 0;
        border: 2px solid #475b82;
        border-top-width: 1px;
        background-color: #fff;

        h4 {
            height: 29px;
            margin: 0 -1px;
            border-bottom: 1px solid #333;
            background: #475b82;
            font-weight: bold;
            color: #fff;
            overflow: hidden;

            div.title,
            > span {
                display: block;
                overflow: hidden;
                height: 14px;
                margin: 7px 10px 0 10px;
                font-size: 12px;
                line-height: 14px;
            }
        }

        .cont {
            zoom: 1;
            overflow: hidden;
            padding: 10px;

            .my_benefit {
                text-align: center;
                border: 1px solid #ededed;
                background: #f4f4f4;
                padding: 20px 10px;

                strong {
                    display: block;
                    margin: 0 0 10px 0;
                    font-size: 15px;
                    color: #e90100;
                    font-weight: bold;
                    line-height: 20px;
                    letter-spacing: -1px;
                }

                span {
                    display: block;
                    margin: 0 0 18px 0;
                    font-size: 11px;
                    color: #666;
                    line-height: 14px;
                }

                em {
                    display: block;
                    font-size: 12px;
                    font-weight: bold;
                    color: #333;
                    line-height: 20px;
                }
            }
        }
    }
}

// [파워링크]
.sponsor_link_wrap {
    width: 100%;
    background: #fff;
    margin-top: 40px;

    &.no_line {
        border: none;
    }

    .sponsor_link_renwl {
        width: 1200px;
        margin: 0 auto;
        padding: 0;
        border: none;
        position: relative;

        h3 {
            display: inline-block;
            width: auto;
            font: 22px/1.4 $font-family-default;
            background: none;
            text-indent: 0;
            color: #111;

            span {
                font-size: 12px;
                color: #666;
            }
            & + .help_ad {
                top: -2px;
            }
        }

        .top_wrap {
            position: relative;
            height: 40px;

            .nwtxt_link {
                position: absolute;
                top: 10px;
                right: 0;
            }
        }

        ol {
            display: inline-block;
            margin: 0 0 -18px;
            padding: 20px 0 0 20px;
            border-top: 1px solid #dadada;

            li {
                display: inline-block;
                width: 1100px;
                padding: 0 0 18px 20px;
                margin: 0 !important;

                > span {
                    width: 15px;
                    height: 14px;
                    margin-top: 1px;
                    background: url("/img/common/v2/sp_common_v2.png") no-repeat;
                    vertical-align: -0.2em;
                }

                .num01 {
                    background-position: -153px -184px;
                }

                .num02 {
                    background-position: -172px -184px;
                }

                .num03 {
                    background-position: -191px -184px;
                }

                .num04 {
                    background-position: -210px -184px;
                }

                .num05 {
                    background-position: -229px -184px;
                }

                .num06 {
                    background-position: -248px -184px;
                }

                .num07 {
                    background-position: -267px -184px;
                }

                .num08 {
                    background-position: -286px -184px;
                }

                strong {
                    font: 14px $font-family-default;
                    text-decoration: underline;
                    color: #00c;

                    em {
                        font-weight: bold;
                    }
                }

                > em {
                    font-size: 12px;
                    font: 14px/1.3 $font-family-default;
                    color: #323232;
                }

                span {
                    display: inline-block;
                    color: #7c5d3e;
                    font: 12px $font-family-number;
                    padding: 0 2px;
                }

                .t_bold {
                    font: bold 14px/1 $font-family-default;
                    text-decoration: none;
                    color: #333;
                }
            }
        }
    }
}
