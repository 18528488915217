//공통 동영상
.video11box {
    position: relative;
    width: 500px;
    height: 370px;
    margin: 0 auto 20px auto;
    background: #000;
    text-align: center;

    video {
        width: 100%;
        height: 100%;
        vertical-align: top;
    }
    .btn_play {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .ico_mov {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin: -33px 0 0 -33px;
        width: 66px;
        height: 66px;
        background: url("/img/common/v2/sp_common_v2.png") no-repeat 0 -430px;
        line-height: 300px;
    }
    .nosupport {
        position: relative;
        padding: 40px 30px 0 30px;
        color: #fff;
        font-size: 16px;
        line-height: 1.5;
        z-index: 5;

        a {
            display: inline-block;
            margin: 15px 0 0 0;
            color: #2cb1f8;
            text-decoration: underline;
        }
    }
}
